import React, { useState } from 'react';
import styled from 'styled-components';
import firebase from 'gatsby-plugin-firebase';
import { motion } from 'framer-motion';
import { Link, navigate } from 'gatsby';

import Form from '../../components/Form';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import AngleRight from '../../images/svg/angle-right-solid.svg';

const RsvpPage = () => {
  const [name, setName] = useState('');
  const [foundParties, setFoundParties] = useState([]);
  const [noneFound, setNoneFound] = useState(false);

  const [isLoading, setLoading] = useState(false);

  function handleUnlock(e) {
    e.preventDefault();
    setLoading(true);
    let found = [];
    // get all parties from firebase
    firebase
      .firestore()
      .collection('rsvp-fake')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data();
          const id = doc.id;
          data.party.some(member => {
            if (Object.values(member).join(' ').includes(name)) {
              found.push({ party: data.party, id: id });
              return true;
            } else return false;
          });
        });
      })
      .catch(error => {
        console.log(error);
      })
      .then(() => {
        // if only one party found, take to form
        if (found.length === 1) {
          navigate('/rsvp/form', { state: { party: found[0].party, id: found[0].id } });
        }
        // if no partiies found, show error message
        if (found.length === 0) {
          setFoundParties([]);
          setNoneFound(true);
          setLoading(false);
          // if multiple parties found, show select ui
        } else {
          setFoundParties(found);
          setNoneFound(false);
          setLoading(false);
        }
      });
  }

  return (
    <>
      <h1 className='heading'>RSVP</h1>
      <p style={{ textAlign: 'center' }}>Please RSVP by August 17</p>

      <section>
        <p>Enter your name to access the RSVP form for you and your group/family.</p>
        <p>
          The wedding is over and this is now a portfolio piece. Try searching for: "Lisa" or
          "Vince" to navigate through the experience.
        </p>
        <FormWrapper>
          <Form onSubmit={handleUnlock}>
            <TextInput name='Name' state={name} setState={setName} />
            {noneFound && <NoneAlert>No parties found, search again.</NoneAlert>}
            <Button isLoading={isLoading}>{isLoading ? 'Searching...' : 'Continue'}</Button>
          </Form>
        </FormWrapper>
      </section>
      {foundParties[1] && (
        <motion.section animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
          <h2 style={{ textAlign: 'center' }}>Select your party:</h2>
          <PartyList>
            {foundParties.map(party => (
              <li key={party.id}>
                <Link id={party.id} to='/rsvp/form' state={{ party: party.party, id: party.id }}>
                  {party.party.map((person, index, party) => {
                    // only display first two members of the party
                    if (index > 1) {
                      return null;
                    }
                    // display 'person & person'
                    if (party.length > 1) {
                      return (
                        <React.Fragment key={person.id}>
                          {person.name && (index === 1 ? person.name : `${person.name} & `)}
                          {person.plusOne && person.name === '' && 'Guest'}
                        </React.Fragment>
                      );
                    }
                    // display 'person'
                    else return <React.Fragment key={person.id}>{person.name}</React.Fragment>;
                  })}
                  <AngleRight />
                </Link>
              </li>
            ))}
          </PartyList>
        </motion.section>
      )}

      <Disclaimer>
        If you have any issues with this form or aren’t seeing your correct names/group, reach out
        to Max or Michael.
      </Disclaimer>
    </>
  );
};

const Disclaimer = styled.p`
  font-size: 14px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PartyList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border-bottom: 1px solid var(--rose);

    color: var(--rose);
    a {
      text-decoration: none;
      padding-left: 0;
      display: flex;
      padding: 16px;
      justify-content: space-between;
      transition: background-color 0.2s;
      :hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    svg {
      height: 22px;
    }
  }
`;

const NoneAlert = styled.p`
  color: #ce0000;
  margin-top: 0;
  font-weight: 500;
  text-align: center;
`;

export default RsvpPage;
